<template>
  <div class="schoolAuthorityManagement">
    <div class="allSelect">
      <el-checkbox v-model="checked" @change="allChange">全选</el-checkbox>
    </div>

    <div class="perTree">
      <el-tree
        :data="menuTree"
        :props="props"
        show-checkbox
        node-key="id"
        ref="userPower"
        default-expand-all
        :render-content="renderTreeEvent"
        @check-change="getAllId"
        @check="handleCheckChange">
      </el-tree>
      <div class="btn_area">
        <el-button @click="goBack">取 消</el-button>
        <el-button class="mainBtn" @click="enterEvent">确 认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { refreshAuthority } from '../../api/jurisdiction'
export default {
  name: 'schoolAuthorityManagement',
  data() {
    return {
      checked: false,
      props: {
        children: 'children',
        label: 'name',
        value: 'id'
      },
      menuTree: [],
      buttonIds: [],
      menuIds: [], // 已有权限的id集合
      arrLength: [], // 所有权限的id集合
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // if(newVal.query.hasOwnProperty('type')) {
          this.roleId = newVal.query.id;
          this.getMenuTreeData(newVal.query.id);
        // }
      }
    }
  },
  mounted() {
    setTimeout(()=>{
      this.changeCss();
    }, 1000)
  },
  methods: {
    // 获取树型权限数据
    getMenuTreeData(roleId) {
      this.$request.schoolRoleMenuTree({roleId}).then( res=>{
        if(res.data.code == 0) {
          if(res.data.data.menuTree[0].name == '首页') res.data.data.menuTree.shift();
          this.menuTree = res.data.data.menuTree;
          this.menuIds = JSON.parse(JSON.stringify(res.data.data.menuIds));
          this.buttonIds = JSON.parse(JSON.stringify(res.data.data.buttonIds));
          this.setCheckedKeys();
          this.recursionId(this.menuTree);
          setTimeout(() => {
            this.checked = this.arrLength.length == this.menuIds.length ? true : false;
          }, 300);
        }
      })
    },
    // 递归所有id，判断是否全选
    recursionId(data) {
      data.forEach((item, index) => {
        this.arrLength.push(item.id)
        if(item.children && item.children.length != 0) {
          this.recursionId(item.children)
        }
      })
    },
    // 全选
    allChange(data) {
      if (this.checked) {
        this.menuIds = this.arrLength;
        this.$refs.userPower.setCheckedNodes(this.menuTree);
      } else {
        this.menuIds = [];
        this.$refs.userPower.setCheckedKeys([]);
      }
    },
    setCheckedKeys() {
      this.$refs.userPower.setCheckedKeys(this.buttonIds);
    },

    renderTreeEvent(h, { node, data, store }) {
      let classname = ''
      if (node.level === 4) {
        classname = 'levelname'
      }
      return (
        <p class={classname}>
          {node.label}
        </p>
      )
    },
    changeCss() {
      var levelName = document.getElementsByClassName('levelname') // levelname是上面的最底层节点的名字

      for (var i = 0; i < levelName.length; i++) {
          levelName[i].parentNode.style.cssText = 'float: left'
          levelName[i].parentNode.className = 'el-tree-node__content option-wrapper'
          levelName[i].parentNode.parentNode.parentNode.style.marginLeft = '70px'
      }
    },
    changeCssOpen() {
      var levelName = document.getElementsByClassName('levelname')
      // setTimeout(()=>{
      //   this.changeCss();
      // }, 0)
      for (var i = 0; i < levelName.length; i++) {
        levelName[i].parentNode.parentNode.parentNode.classList.add('parSttyle')
        // levelName[i].parentNode.parentNode.parentNode.classList.add('parSttyle');
      }
    },
    changeCssClose() {
      var levelName = document.getElementsByClassName('levelname')
      setTimeout(() => {
        for (var i = 0; i < levelName.length; i++) {
          levelName[i].parentNode.parentNode.parentNode.classList.remove('parSttyle');
        }
      }, 0);
    },
    // 选择全选时，获取所有tree的id
    getAllId(data, isFaCheck, isSonCheck) {
      // console.log(data, isFaCheck, isSonCheck)
      if(isFaCheck) {
        this.menuIds.push(data.id);
      }
    },
    enterEvent() {
      let obj = {
        roleId: this.roleId,
        menuIdSet: this.menuIds
      }
      // console.log(this.menuIds,985)
      // return;
      this.$request.setupPermissionsApi(obj).then(res=>{
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          // refreshAuthority();
          this.goBack();
        }
      })
    },
    handleCheckChange(val, data) {
      // console.log(val, data,777)
      let arr = data.checkedKeys;
      this.menuIds = arr.concat(data.halfCheckedKeys);
      this.checked = this.menuIds.length == this.arrLength.length ? true : false;
    },
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang='less'>
  .option-wrapper { padding: 0 !important; } 
  .schoolAuthorityManagement {
    background-color: #fff;
    // display: flex;
    // flex-direction: column;
    // align-items: center; 
    box-sizing: border-box;
    padding: 40px;
    height: calc(100% - 68px);
    .allSelect {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #F2F2F2;
      box-sizing: border-box;
      padding-left: 8px;
    }
    .el-tree {
      padding: 10px 10px;
    }
    .btn_area {
      margin: 20px 0px 20px;
      text-align: center;
    }
    .parSttyle {
      display: flex!important;
    }
    // .el-tree-node__children {
    //   display: flex!important;
    // }
  }
</style>